import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/teaboy/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import withRouter from "../../Components/Common/withRouter";
import { useAuth } from "../../stores";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgetPasswordPage = (props) => {
  const { forgetPassword, isLoading, errorMsg, successMsg, clearMessages } =
    useAuth();

  useEffect(() => {
    clearMessages();

    if (successMsg) {
      toast.success(successMsg);
    }
    if (errorMsg) {
      toast.error(errorMsg);
    }

    return () => {
      clearMessages();
    };
  }, [clearMessages, successMsg, errorMsg]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      localStorage.setItem("resetEmail", values.email);
      forgetPassword(values, props.router);
    },
  });

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12} className="text-center mt-sm-5 mb-4 text-white-50">
              <Link to="/" className="d-inline-block auth-logo">
                <img src={logoLight} alt="" height="20" />
              </Link>
              <p className="mt-3 fs-15 fw-medium">
                Elevating next-level hospitality.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password?</h5>
                    <p className="text-muted">Reset password with ClickOrder</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>

                  <Alert className="alert-borderless alert-warning text-center mb-2 mx-2">
                    Enter your email and instructions will be sent to you!
                  </Alert>

                  <div className="p-2">
                    <Form onSubmit={validation.handleSubmit}>
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Enter email"
                          {...validation.getFieldProps("email")}
                          invalid={
                            validation.touched.email &&
                            !!validation.errors.email
                          }
                        />
                        {validation.errors.email && (
                          <FormFeedback>{validation.errors.email}</FormFeedback>
                        )}
                      </div>

                      <div className="text-center mt-4">
                        <Button
                          className="btn w-100 text-white font-bold"
                          style={{ background: "#ce9558" }}
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : null}
                          Send Reset Link
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Click here
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer autoClose={2000} limit={1} /> */}
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
