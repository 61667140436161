import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

const MenuItemOptionsDropdown = ({
  defaultOptions,
  selectedOption,
  onSelect,
  onCreateCustom,
  createOption,
  itemId,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedExistingOption, setSelectedExistingOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleChangeExistingOption = (existingOptionId) => {
    setSelectedExistingOption(Number(existingOptionId));
  };

  const handleAddExistingOption = async () => {
    if (!selectedExistingOption) {
      return;
    }
    setLoading(true);
    await createOption(itemId, {
      existing_option_id: selectedExistingOption,
    });
    setSelectedExistingOption(null);
    setLoading(false);
  };

  const selectedOptionData = defaultOptions.find(
    (opt) => opt.menu_item_option_id === selectedOption
  );

  const selectedLabel = selectedOptionData
    ? `${selectedOptionData.name}${
        selectedOptionData.choices?.length > 0
          ? ` (${selectedOptionData.choices.map((c) => c.name).join(", ")})`
          : ""
      }`
    : "Add an option";

  return (
    <div className="d-flex flex-row gap-3 align-items-center">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="flex-grow-1">
        <DropdownToggle color="primary" className="w-100">
          {selectedLabel}
        </DropdownToggle>
        <DropdownMenu style={{ width: "100%" }}>
          {defaultOptions.map((option) => (
            <DropdownItem
              key={option.menu_item_option_id}
              style={{ width: "100%" }}
              onClick={() => {
                console.log(
                  "option.menu_item_option_id",
                  option.menu_item_option_id
                );
                handleChangeExistingOption(option.menu_item_option_id);
                onSelect(option.menu_item_option_id);
              }}
            >
              {option.name}
              {option.choices.length > 0
                ? ` (${option.choices.map((c) => c.name).join(", ")})`
                : ""}
            </DropdownItem>
          ))}
          <DropdownItem divider />
          <DropdownItem
            onClick={onCreateCustom}
            className="text-primary"
            style={{ width: "100%" }}
          >
            + Create Custom One
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {selectedExistingOption && (
        <Button
          color="success"
          onClick={handleAddExistingOption}
          disabled={loading}
        >
          {loading ? "Loading..." : "Add"}
        </Button>
      )}
    </div>
  );
};

export default MenuItemOptionsDropdown;
