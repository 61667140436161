import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Input,
} from "reactstrap";
import { useSpaceStore } from "../../../../stores/Assets/space";

const AddSubSpace = ({
  menuIDs,
  kitchenID,
  space_id,
  showAddSubSpaceModal,
  toggleAddSubSpaceModal,
}) => {
  const [subSpaceCount, setSubSpaceCount] = useState(1); // Default to 1
  const { createSubSpaces, isLoading } = useSpaceStore((state) => state);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      subSpaceCount: Yup.number().min(1).required(t("Required")),
    }),
    onSubmit: async (values) => {
      const { name } = values;

      // Generate subspace list
      const subSpaces = Array.from({ length: subSpaceCount }, (_, i) => ({
        name: `${name}#${i + 1}`,
        space_id: parseInt(space_id),
      }));

      // Create body for API request
      const body = { subSpaces: subSpaces };

      const result = await createSubSpaces(space_id, body, kitchenID, menuIDs);
      if (result) {
        formik.resetForm();
        toggleAddSubSpaceModal();
      }
    },
  });

  return (
    <Modal isOpen={showAddSubSpaceModal} toggle={toggleAddSubSpaceModal}>
      <ModalHeader toggle={toggleAddSubSpaceModal}>
        {t("Add New SubSpaces")}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          {/* Input for Base Name */}
          <Label htmlFor="name">{t("Base Name")}</Label>
          <Input
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.errors.name && formik.touched.name ? "is-invalid" : ""
            }
          />
          {formik.errors.name && formik.touched.name && (
            <div className="invalid-feedback">{formik.errors.name}</div>
          )}

          {/* Input for Subspace Count */}
          <Label htmlFor="subSpaceCount" className="mt-3">
            {t("Number of SubSpaces")}
          </Label>
          <Input
            id="subSpaceCount"
            name="subSpaceCount"
            type="number"
            min={1}
            max={100}
            value={subSpaceCount}
            onChange={(e) => {
              setSubSpaceCount(e.target.value);
              formik.setFieldValue("subSpaceCount", e.target.value);
            }}
          />
          {formik.errors.subSpaceCount && formik.touched.subSpaceCount && (
            <div className="invalid-feedback">
              {formik.errors.subSpaceCount}
            </div>
          )}

          {/* Buttons */}
          <div className="d-flex justify-content-end mt-4 gap-3">
            <Button type="submit" color="success" disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : t("Add")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={() => {
                toggleAddSubSpaceModal();
                formik.resetForm();
              }}
              disabled={isLoading}
            >
              {t("Cancel")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddSubSpace;
