export function timeConversion(seconds) {
  seconds = Math.floor(seconds);
  if (seconds < 60) {
    return `${seconds} s`;
  }

  const minutes = Math.floor(seconds / 60);
  console.log(minutes, seconds - minutes * 60);

  if (minutes < 60) {
    return `${minutes} min ${seconds - minutes * 60} s`;
  }

  const hours = Math.floor(minutes / 60);

  if (hours < 24) {
    return `${hours} hr${hours === 1 ? "" : "s"}${minutes - hours * 60} min`;
  }

  const days = Math.floor(hours / 24);

  if (days < 7) {
    return `${days} d ${hours - days * 24} hr${hours === 1 ? "" : "s"}`;
  }

  const weeks = Math.floor(days / 7);

  if (weeks < 4) {
    return `${weeks} week${weeks === 1 ? "" : "s"}`;
  }
  const months = Math.floor(weeks / 4);
  // You can add more granularity as needed, e.g., months, years
  if (months < 12) {
    return `${months} m${months === 1 ? "" : "s"}`;
  }
  return "A long time ago";
}
