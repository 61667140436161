import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { CustomDataLabel, YAxis } from "./charts";
import BreadCrumb from "./Breadcrumb";
import Section from "./Breadcrumb";
import { useDashboardStore } from "../../stores/Dashboard";
import { useSiteStore } from "../../stores/Assets/site";
import { timeConversion } from "../../Components/Common/timeConversion";
const Dashboard = () => {
  document.title = "ClickOrder Admin";
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [siteList, setSiteList] = useState([]);
  const { dailyStats, weeklyServices, weeklyOrders, isLoading, fetchStats } =
    useDashboardStore((state) => state);
  const { getSites } = useSiteStore((state) => state);
  const getDailyReport = async () => {
    fetchStats(selectedSiteIds, selectedDate); // Fetch data when site or date changes
  };
  useEffect(() => {
    getDailyReport(selectedSiteIds, selectedDate);
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      const list = await getSites(); // Fetch sites
      const formattedOptions = list?.map((site) => {
        return {
          label: `${site.site_id}-${site.name}`,
          value: site.site_id,
        };
      });
      setSiteList(formattedOptions);
    };

    fetchSites(); // Call the fetch function
  }, [getSites]);

  const counterWidgets = [
    {
      id: 1,
      label: "Total Orders",
      labelClass: "muted",

      counter: dailyStats?.order_count,
      caption: "See details",
      captionisLink: true,
      icon: "ri-shopping-bag-line",
      iconClass: "soft-success",
      color: "success",
      decimals: 0,
      suffix: " Orders",
    },
  ];
  const stringWidgets = [
    {
      id: 1,
      label: "Avg. DELIVERY TIME",
      labelClass: "muted",
      top: timeConversion(dailyStats?.average_delivery_time || 0),
      caption: "Based on delivered orders",
      captionisLink: false,

      captionClass: "muted",
      icon: "ri-timer-line",
      iconClass: "soft-secondary",
      color: "secondary",
      decimals: 1,
      suffix: " min",
    },
    {
      id: 1,
      label: "",
      labelClass: "muted",
      label: "TOP SERVICE",
      top: dailyStats?.top_service?.item || "N/A",
      caption: `Ordered ${dailyStats?.top_service?.order_count || 0} times`,
      captionisLink: false,
      captionClass: "muted",

      icon: "ri-funds-box-line",
      iconClass: "soft-info",
      color: "info",
      decimals: 0,
      suffix: " min",
    },
    {
      id: 1,
      label: "TOP SPACE",
      labelClass: "muted",
      top: dailyStats?.top_space?.name || "N/A",
      caption: `Ordered ${dailyStats?.top_space?.order_count || 0} times`,
      captionisLink: false,
      captionClass: "muted",
      icon: "ri-road-map-line",
      iconClass: "soft-primary",
      color: "primary",
      decimals: 0,
      suffix: " Orders",
    },
  ];

  // if (isLoading) return <div>Loading...</div>; // Show loading state while fetching data

  return (
    <React.Fragment>
      <Container fluid>
        <Section
          getDailyReport={getDailyReport}
          setSelectedDate={setSelectedDate}
          selectedSiteIds={selectedSiteIds}
          setSelectedSiteIds={setSelectedSiteIds}
          siteList={siteList}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Row>
              {(counterWidgets || []).map((item, key) => (
                <Col xl={3} md={6} key={key}>
                  <Card className={"card-animate"}>
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p
                            className={
                              "text-uppercase fw-medium mb-0 text-" +
                              item.labelClass
                            }
                          >
                            {item.label}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4
                            className={
                              "fs-22 fw-semibold ff-secondary mb-4 " +
                              item.counterClass
                            }
                          >
                            <span
                              className="counter-value"
                              data-target="559.25"
                            >
                              <CountUp
                                start={0}
                                // prefix={item.prefix}
                                suffix={item.suffix}
                                separator={item.separator}
                                end={item.counter}
                                duration={4}
                              />
                            </span>
                          </h4>
                          {item.captionisLink ? (
                            <Link
                              to={{
                                pathname: "/orders", // Change this to the actual route of your Orders page
                                search: `?site_id=${
                                  selectedSiteIds.length > 0
                                    ? selectedSiteIds[0]
                                    : ""
                                }&date=${selectedDate}`,
                              }}
                              className={
                                "text-decoration-underline " + item.captionClass
                              }
                            >
                              {item.caption}
                            </Link>
                          ) : (
                            <p className={"mb-0 text-" + item.captionClass}>
                              {item.caption}
                            </p>
                          )}
                        </div>
                        <div className="avatar-sm flex-shrink-0">
                          <span
                            className={
                              "avatar-title rounded fs-3 bg-" + item.iconClass
                            }
                          >
                            <i
                              className={item.icon + " text-" + item.color}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {(stringWidgets || []).map((item, key) => (
                <Col xl={3} md={6} key={key}>
                  <Card className={"card-animate"}>
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p
                            className={
                              "text-uppercase fw-medium mb-0 text-" +
                              item.labelClass
                            }
                          >
                            {item.label}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4
                            className={
                              "fs-22 fw-semibold ff-secondary mb-4 " +
                              item.counterClass
                            }
                          >
                            <span>{item.top}</span>
                          </h4>
                          {item.captionisLink ? (
                            <Link
                              to="#"
                              className={
                                "text-decoration-underline " + item.captionClass
                              }
                            >
                              {item.caption}
                            </Link>
                          ) : (
                            <p className={"mb-0 text-" + item.captionClass}>
                              {item.caption}
                            </p>
                          )}
                        </div>
                        <div className="avatar-sm flex-shrink-0">
                          <span
                            className={
                              "avatar-title rounded fs-3 bg-" + item.iconClass
                            }
                          >
                            <i
                              className={item.icon + " text-" + item.color}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row>
              <Col lg={6}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      Weekly Total Order and Delivery Time
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <YAxis
                      weeklyOrders={weeklyOrders}
                      dataColors='["--vz-primary", "--vz-info", "--vz-success"]'
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col lg={6}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Weekly Ordered Services</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomDataLabel
                      weeklyServices={weeklyServices}
                      dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
