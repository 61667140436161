import React, { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../stores";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logoLight from "../../assets/teaboy/logo-light.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ValidateEmail = () => {
  const { token } = useParams();
  const { validateEmail, isLoading, successMsg, errorMsg, clearMessages } =
    useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    clearMessages(); // Clear old messages on mount
    if (token) validateEmail({ token });
  }, [token, validateEmail, clearMessages]);

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg);
      setTimeout(() => navigate("/login"), 3000); // Redirect after 3 sec
    }
    if (errorMsg) toast.error(errorMsg);
  }, [successMsg, errorMsg, navigate]);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logoLight} alt="logo" height="20" />
                </Link>
                <p className="mt-3 fs-15 fw-medium">
                  Elevating next-level hospitality.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4 text-center">
                  <h5 className="text-primary">Email Validation</h5>
                  <p className="text-muted">
                    Please wait while we validate your email.
                  </p>

                  {isLoading && (
                    <div className="my-3">
                      <Spinner size="lg" color="primary" />
                      <p className="mt-2">Validating your email...</p>
                    </div>
                  )}

                  {successMsg && <Alert color="success">{successMsg}</Alert>}
                  {errorMsg && <Alert color="danger">{errorMsg}</Alert>}

                  <div className="mt-4">
                    <Button
                      color="primary"
                      onClick={() => navigate("/login")}
                      disabled={isLoading}
                    >
                      Go to Login
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer autoClose={2000} limit={1} /> */}
    </ParticlesAuth>
  );
};

export default ValidateEmail;
