import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { useOptionStore } from "../../../stores/Assets/ItemOptions";
import MenuItemOptionsDropdown from "./MenuItemOptionsDropDown";
import OptionCard from "./OptionCard";
import OptionForm from "./OptionForm";

export const OptionsModal = ({ isOpen, toggle, itemId }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [optionList, setOptionList] = useState([]);

  const [loadingFetch, setFetchLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    getOptions,
    defaultOptions,
    getDefaultOptions,
    options,
    createOption,
    deleteOption,
  } = useOptionStore((state) => state);

  useEffect(() => {
    const fetchOptions = async () => {
      setFetchLoading(true);
      try {
        await getDefaultOptions();
        await getOptions(itemId);
      } catch (error) {
        console.error("Failed to fetch options", error);
      } finally {
        setFetchLoading(false);
      }
    };
    if (isOpen) {
      fetchOptions();
    }
  }, [itemId, isOpen, getOptions, getDefaultOptions]);

  useEffect(() => {
    setOptionList(options);
  }, [options]);

  const handleDeleteOption = async (optionId) => {
    await deleteOption(itemId, optionId);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("View Options")}</ModalHeader>
      <ModalBody>
        {loadingFetch ? (
          <div className="text-center">
            <Spinner color="primary" />
            <p>{t("Loading options...")}</p>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column gap-4 mb-2">
              {optionList.length > 0 ? (
                <div className="d-flex flex-column gap-1">
                  {optionList.map((opt) => (
                    <OptionCard
                      key={opt.menu_item_option_id}
                      option={opt}
                      onDelete={handleDeleteOption}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <p>{t("No Options for this Item...")}</p>
                </div>
              )}
            </div>
            {!isFormVisible && defaultOptions.length > 0 && (
              <MenuItemOptionsDropdown
                defaultOptions={defaultOptions}
                selectedOption={selectedOption}
                onSelect={setSelectedOption}
                onCreateCustom={() => setIsFormVisible(true)}
                createOption={createOption}
                itemId={itemId}
              />
            )}
            {isFormVisible && (
              <OptionForm itemId={itemId} setIsFormVisible={setIsFormVisible} />
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
