// import * as Dashboards from "./Dashboards";

import Dashboard from "./Dashboard";
import Settings from "./Settings";
import Orders from "./Orders";
import Menus from "./Menus";
import MenuItems from "./MenuItems";
import Sites from "./Locations/Sites";
import Kitchens from "./Locations/Kitchens";
import SubSpaces from "./Locations/SubSpaces";
import Spaces from "./Locations/Spaces";

export {
  Spaces,
  SubSpaces,
  Menus,
  MenuItems,
  Sites,
  Kitchens,
  Orders,
  Settings,
  Dashboard,
};
