import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSpaceStore } from "../../../stores/Assets/space";
import { useConfirmDialogStore } from "../../../stores/Modal/ConfirmDialogStore";
import { t } from "i18next";
import DataTableBase from "../../../Components/Common/DataTableBase/DataTableBase";
import AddSubSpaces from "./components/AddSubSpaces";
import EditSubSpace from "./components/EditSubSpace";
import QRmodal from "../Spaces/Components/QRmodal";

const SubSpaces = () => {
  const { spaceid } = useParams();
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    getSubSpaces,
    getSpace,
    space,
    deleteSubSpace,
    subspaces,
    isLoading,
  } = useSpaceStore((state) => state);
  const [kitchenID, setKitchenID] = useState(null);
  const [menuIDs, setMenuIDs] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);
  const [selectedRowQR, setselectedRowQR] = useState(false);

  useEffect(() => {
    if (spaceid) {
      getSpace(spaceid);
      getSubSpaces(spaceid);
    }
  }, [spaceid]);

  useEffect(() => {
    if (space) {
      setKitchenID(space?.kitchen_id);
      const menu_ids = space?.menus?.map((menu) => menu?.menu_id);
      setMenuIDs(menu_ids);
      console.log(space?.kitchen_id, menu_ids);
    }
  }, [space]);
  useEffect(() => {
    setTotalRows(subspaces?.length || 0);

    if (subspaces.length === 0) {
      setShowAddSubSpaceModal(true);
    } else {
      setShowAddSubSpaceModal(false);
    }
  }, [subspaces]);
  const columns = [
    {
      name: t("Subspace-ID"),
      selector: (row) => row?.sub_space_id,
      sortable: true,
      wrap: true,
    },
    {
      name: t("Space-ID"),
      selector: (row) => row?.space_id,
      sortable: true,
      wrap: true,
      // cell: (row) => (
      //   <div className="d-flex flex-row justify-content-center align-items-center gap-2">
      //     <span>{row?.title}</span>
      //   </div>
      // ),
    },
    {
      name: t("Name"),
      selector: (row) => row?.name,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <span>{row?.name}</span>
        </div>
      ),
    },
    {
      name: t("QR Code"),
      // width: "95px",
      // selector: (row) => row?.status,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="cursor-pointer">
          <span
            style={{ fontSize: "14px" }}
            className={`badge bg-soft-success cursor-pointer text-success text-uppercase`}
            onClick={() => handleQRClick(row)}
          >
            {<i className="ri-external-link-line"></i>} View
          </span>
        </div>
      ),
    },
  ];

  const { showConfirm } = useConfirmDialogStore((state) => state);

  const [showAddSubSpaceModal, setShowAddSubSpaceModal] = useState(false);
  const [showEditSubSpaceModal, setshowEditSubSpaceModal] = useState(false);
  const toggleAddSubSpaceModal = () => {
    setShowAddSubSpaceModal(!showAddSubSpaceModal);
  };

  const deleteSubSpaceFun = async (spaceid, id) => {
    try {
      await deleteSubSpace(spaceid, id);
    } catch (e) {
      console.log(e);
    }
  };
  const toggleEditSubSpace = (row) => {
    setSelectedRow(row);
    setshowEditSubSpaceModal(!showEditSubSpaceModal);
  };

  const handleQRClick = (row) => {
    setselectedRowQR(row);

    setShowQRCode(!showQRCode);
  };
  return (
    <>
      <DataTableBase
        tableTitle={"Sub-Spaces"}
        data={subspaces}
        columns={columns}
        loading={isLoading}
        paginationTotalRows={totalRows}
        // onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        onHeaderAddBtnClick={toggleAddSubSpaceModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        // onRowEditBtnClick={toggleEditItemModal}
        // onRowDeleteBtnClick={toggleDeleteMenuModal}
        // onSearchIconClick={searchHandler}
        actionColWidth="100px"
        showSearch={false}
        showSubHeader={true}
        showActionButtons={true}
        customActionBtns={(row) => (
          <>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                toggleEditSubSpace(row);
              }}
              title="Edit"
            >
              <i className="ri-edit-fill"></i>{" "}
            </button>

            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                showConfirm(
                  () => {
                    deleteSubSpaceFun(spaceid, row?.sub_space_id);
                  },
                  () => {
                    console.log("Cancelled");
                  },
                  "Confirmation", // Title of the confirmation dialog
                  `Are you sure you want to delete the menu Item?` // Question displayed in the dialog
                );
              }}
              title="Delete"
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </>
        )}
      ></DataTableBase>
      {/* <Pagination
        currentPage={currentPage}
        totalRows={items?.length || 0}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
      /> */}

      {showAddSubSpaceModal && (
        <AddSubSpaces
          menuIDs={menuIDs}
          kitchenID={kitchenID}
          toggleAddSubSpaceModal={toggleAddSubSpaceModal}
          showAddSubSpaceModal={showAddSubSpaceModal}
          space_id={spaceid}
        />
      )}

      {selectedRow && (
        <EditSubSpace
          toggleEditSubSpace={toggleEditSubSpace}
          showEditSubSpaceModal={showEditSubSpaceModal}
          rowData={selectedRow}
          space_id={spaceid}
        />
      )}
      {selectedRowQR && (
        <QRmodal
          info={selectedRowQR}
          showQRCode={showQRCode}
          toggleQRModal={() => setShowQRCode(!showQRCode)}
        />
      )}
    </>
  );
};

export default SubSpaces;
