import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import ReactSelect from "react-select";
import styled from "styled-components";
import {
  prepareSelectOptions,
  reactSelectStyling,
} from "../../Components/Common/Forms/FormikInputHelper";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import { tokenHelper } from "../../stores/helpers";

const Section = ({
  selectedSiteIds,
  setSelectedSiteIds,
  setSelectedDate,
  siteList,
  getDailyReport,
}) => {
  const [userName, setUserName] = useState("user");
  useEffect(() => {
    const info = async () => {
      const user = await tokenHelper.getUser();
      setUserName(user.name);
    };

    info();
  }, []);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Hello {userName}!</h4>
              <p className="text-muted mb-0">Here is your daily insight.</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <StyledFilterWrapper>
                        <ReactSelect
                          {...reactSelectStyling}
                          options={siteList}
                          placeholder="Select Site..."
                          //options={field.selectOptions}
                          isSearchable={true}
                          isClearable={true}
                          name={"search"}
                          isMulti={true}
                          onChange={(selectedOptions) =>
                            setSelectedSiteIds(
                              selectedOptions.map((option) => option.value)
                            )
                          }
                        />
                        {/* <Select
                          id="Site"
                          isMulti
                          options={siteList}
                          classNames={"bg-white"}
                          // value={siteList.filter((site) =>
                          //   selectedSiteIds.includes(site.site_id)
                          // )}
                          onChange={(selectedOptions) =>
                            setSelectedSiteIds(
                              selectedOptions.map((option) => option.value)
                            )
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                        /> */}
                      </StyledFilterWrapper>

                      {/* put a selector here */}
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <Flatpickr
                      className="form-control border-0 dash-filter-picker shadow"
                      placeholder="Select Date..."
                      options={{
                        mode: "single",
                        dateFormat: "d M, Y",
                      }}
                      onChange={(e) =>
                        setSelectedDate(moment(e[0]).format("yyyy-MM-DD"))
                      }
                    />
                  </div>
                  <div className="col-sm-auto">
                    <Button onClick={getDailyReport}>
                      <i className=""></i> Filter
                    </Button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;

const StyledFilterWrapper = styled.div`
  min-width: 200px;
  // flex: 1;
`;
