import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

const YAxis = ({ dataColors, weeklyOrders }) => {
  // Convert max_delivery_time from seconds to minutes or hours
  console.log(weeklyOrders);

  const series = [
    {
      name: "Ordered",
      type: "bar", // Ensure it stays on the first Y-axis
      data: weeklyOrders?.map((order) => parseInt(order?.ordered) || 0),
    },
    {
      name: "Delivered",
      type: "bar", // Ensure it stays on the first Y-axis
      data: weeklyOrders?.map((order) => parseInt(order?.delivered) || 0),
    },
    {
      name: "Max. Delivery Time",
      type: "line", // This will automatically use the second Y-axis
      data: weeklyOrders?.map(
        (order) => Number(order?.max_delivery_time) / 60 || 0
      ), // Convert seconds to minutes
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line", // Mixed chart with bars + line
    },
    xaxis: {
      categories: weeklyOrders?.map((order) => order.day) || [],
    },
    yaxis: [
      {
        title: {
          text: "Orders", // This Y-axis is for Ordered & Delivered
        },
        seriesName: "Ordered",

        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
      {
        seriesName: "Ordered",
        show: false,

        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
      {
        title: {
          text: "Time (in minutes)",
        },
        seriesName: "Max. Delivery Time",

        opposite: true, // This Y-axis is for Max Delivery Time

        labels: {
          formatter: (value) => value.toFixed(0) + " min",
        },
      },
    ],
    stroke: {
      width: [0, 0, 3], // 0 for bars, 3px for line chart
    },
  };

  return (
    <ReactApexChart
      dir="ltr"
      options={options}
      series={series}
      type="line" // Since line is the default, you can leave it here if you'd prefer
      height={350}
    />
  );
};

const CustomDataLabel = ({ dataColors, weeklyServices }) => {
  // Create a fallback series if data is loading or empty
  const seriesData = weeklyServices?.map((service) => service?.order_count);

  const categories = weeklyServices?.map((service) => service?.item);

  const chartDatalabelsBarColors = getChartColorsArray(dataColors);

  const series = [
    {
      data: seriesData || [],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: chartDatalabelsBarColors,
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories || [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    title: {
      text: "Top Ordered Services",
      align: "center",
      floating: true,
      style: {
        fontWeight: 500,
      },
    },
    // subtitle: {
    //   text: "Category Names as DataLabels inside bars",
    //   align: "center",
    // },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export { YAxis, CustomDataLabel };
