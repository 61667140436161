import { create } from "zustand";
import { axiosHelper, tokenHelper } from "../helpers";
import { setCookie, removeCookie } from "../../helpers/cookies";
import { encrypt } from "../../helpers/sessions";

export const useAuth = create((set) => ({
  user: tokenHelper.getUser(),
  accessToken: tokenHelper.getToken(),
  successMsg: null,
  isLoading: false,
  error: null,
  errorMsg: null,

  setError: (msg) => set({ errorMsg: msg, successMsg: null, isLoading: false }),
  setSuccess: (msg) =>
    set({ successMsg: msg, errorMsg: null, isLoading: false }),
  clearMessages: () => set({ successMsg: null, errorMsg: null }),

  login: async (data, router) => {
    set({ isLoading: true, errorMsg: null, successMsg: null });

    try {
      const response = await axiosHelper.post(
        "/user/auth/login-portal",
        JSON.stringify(data)
      );
      const { accessToken } = response;

      if (!accessToken) throw new Error("Invalid response from authentication");

      const response2 = await axiosHelper.get("/user/infos", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (["USER", "NORMAL_USER"].includes(response2.role?.toUpperCase())) {
        set({ errorMsg: "Not Allowed", isLoading: false });
        return;
      }

      const expiresAt = new Date(Date.now() + 60 * 60 * 1000);
      const session = await encrypt({
        accessToken,
        expiresAt,
        user: response2,
      });

      tokenHelper.setToken(accessToken);
      setCookie("session", session, {
        expires: expiresAt,
        secure: true,
        sameSite: "lax",
      });

      set({ successMsg: "Login Successful", isLoading: false });
      router.navigate("/dashboard");
    } catch (error) {
      set({ errorMsg: error.response?.data?.message, isLoading: false });
    }
  },

  logout: () => {
    removeCookie("session");
    tokenHelper.removeToken();
    tokenHelper.removeUser();
  },

  signup: async (data) => {
    set({ isLoading: true });

    try {
      await axiosHelper.post("/v2/user/auth/register", {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        role: "ADMIN",
        userLanguage: "EN",
        max_daily_orders: 20,
        phoneOS: "android",
      });
      set({ successMsg: "Registred Successfully", isLoading: false });
    } catch (error) {
      set({
        errorMsg: "Email already registered, use another email",
        isLoading: false,
      });
    }
  },

  forgetPassword: async (data, router) => {
    set({ isLoading: true });

    try {
      await axiosHelper.post("/v2/user/auth/forgot-password", {
        email: data.email,
      });
      set({
        successMsg: "Reset password link sent to your email",
        isLoading: false,
      });
      router.navigate("/reset-password");
    } catch (error) {
      set({ errorMsg: "Error sending reset password link", isLoading: false });
    }
  },

  resetPassword: async (data, router) => {
    set({ isLoading: true });

    try {
      await axiosHelper.post("/v2/user/auth/reset-password", {
        email: data.email,
        newPassword: data.password,
        otp: data.otp,
      });
      set({ successMsg: "Password reset successfully", isLoading: false });
    } catch (error) {
      set({ errorMsg: "Invalid OTP", isLoading: false });
    }
  },

  validateEmail: async (data) => {
    set({ isLoading: true });

    try {
      await axiosHelper.get(`/user/auth/validate-email/${data.token}`);
      set({ successMsg: "Email Validated Successfully", isLoading: false });
    } catch (error) {
      set({ errorMsg: "Error Validating Email", isLoading: false });
    }
  },
}));
