import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logoLight from "../../assets/teaboy/logo-light.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../stores";
import withRouter from "../../Components/Common/withRouter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = (props) => {
  document.title = "ClickOrder Admin";

  const storedEmail = localStorage.getItem("resetEmail") || "";
  const { resetPassword, isLoading, errorMsg, successMsg, clearMessages } =
    useAuth();

  useEffect(() => {
    clearMessages();

    if (successMsg) {
      toast.success(successMsg);
      setTimeout(() => props.router.navigate("/login"), 3000);
    }
    if (errorMsg) {
      toast.error(errorMsg);
    }

    return () => {
      clearMessages();
    };
  }, [clearMessages, successMsg, errorMsg, props.router]);

  const validation = useFormik({
    initialValues: {
      otp: "",
      email: storedEmail,
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("OTP is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "At least one lowercase letter")
        .matches(/[A-Z]/, "At least one uppercase letter")
        .matches(/[0-9]/, "At least one number")
        .required("Password is required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      resetPassword(values, props.router);
      localStorage.removeItem("resetEmail");
    },
  });

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12} className="text-center mt-sm-5 mb-4 text-white-50">
              <Link to="/" className="d-inline-block auth-logo">
                <img src={logoLight} alt="logo" height="20" />
              </Link>
              <p className="mt-3 fs-15 fw-medium">
                Elevating next-level hospitality.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Create New Password</h5>
                    <p className="text-muted">
                      Enter a new password with the OTP sent to your email.
                    </p>
                  </div>

                  <Form onSubmit={validation.handleSubmit} className="p-2">
                    {/* OTP Input */}
                    <div className="mb-3">
                      <Label htmlFor="otp-input">OTP Code</Label>
                      <Input
                        type="text"
                        id="otp-input"
                        name="otp"
                        placeholder="Enter OTP"
                        {...validation.getFieldProps("otp")}
                        invalid={
                          validation.touched.otp && !!validation.errors.otp
                        }
                      />
                      <FormFeedback>{validation.errors.otp}</FormFeedback>
                    </div>

                    {/* Password Input */}
                    <div className="mb-3">
                      <Label htmlFor="password-input">New Password</Label>
                      <Input
                        type="password"
                        id="password-input"
                        name="password"
                        placeholder="Enter new password"
                        {...validation.getFieldProps("password")}
                        invalid={
                          validation.touched.password &&
                          !!validation.errors.password
                        }
                      />
                      <FormFeedback>{validation.errors.password}</FormFeedback>
                    </div>

                    {/* Confirm Password Input */}
                    <div className="mb-3">
                      <Label htmlFor="confirm-password-input">
                        Confirm Password
                      </Label>
                      <Input
                        type="password"
                        id="confirm-password-input"
                        name="confirm_password"
                        placeholder="Confirm new password"
                        {...validation.getFieldProps("confirm_password")}
                        invalid={
                          validation.touched.confirm_password &&
                          !!validation.errors.confirm_password
                        }
                      />
                      <FormFeedback>
                        {validation.errors.confirm_password}
                      </FormFeedback>
                    </div>

                    {/* OTP Resend */}
                    <Link
                      to="/forgot-password"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Didn’t receive OTP?
                    </Link>

                    {/* Submit Button */}
                    <div className="mt-4">
                      <Button
                        className="btn w-100 text-white font-bold"
                        style={{ background: "#ce9558" }}
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2" />
                        ) : null}
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p>
                  Back to login?{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Click here
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer autoClose={2000} limit={1} /> */}
    </ParticlesAuth>
  );
};

export default withRouter(ResetPassword);
