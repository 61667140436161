import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Input,
} from "reactstrap";
import { useSpaceStore } from "../../../../stores/Assets/space";

const EditSubSpace = ({
  space_id,
  rowData,
  sub_space_id,
  showEditSubSpaceModal,
  toggleEditSubSpace,
}) => {
  const { updateSubSpace, isLoading } = useSpaceStore((state) => state);
  useEffect(() => {
    console.log(rowData);
    if (rowData) {
      formik.setValues({
        name: rowData?.name || "",
      });
    }
  }, [rowData]);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      const { name } = values;

      // Create body for API request

      // Generate subspace list
      const body = {
        name: `${name}`,
        space_id: parseInt(rowData?.space_id),
      };

      // Create body for API request
      const result = await updateSubSpace(
        rowData?.space_id,
        rowData?.sub_space_id,
        body
      );
      if (result) {
        formik.resetForm();
        toggleEditSubSpace();
      }
    },
  });

  return (
    <Modal isOpen={showEditSubSpaceModal} toggle={toggleEditSubSpace}>
      <ModalHeader toggle={toggleEditSubSpace}>
        {t("Edit SubSpace")}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          {/* Input for Base Name */}
          <Label htmlFor="name">{t("Name")}</Label>
          <Input
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.errors.name && formik.touched.name ? "is-invalid" : ""
            }
          />
          {formik.errors.name && formik.touched.name && (
            <div className="invalid-feedback">{formik.errors.name}</div>
          )}

          {/* Buttons */}
          <div className="d-flex justify-content-end mt-4 gap-3">
            <Button type="submit" color="success" disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : t("Edit")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={() => {
                toggleEditSubSpace();
                formik.resetForm();
              }}
              disabled={isLoading}
            >
              {t("Cancel")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditSubSpace;
