import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { Button, Input, Label, Spinner, Card, CardBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useOptionStore } from "../../../stores/Assets/ItemOptions";

const OptionForm = ({ itemId, setIsFormVisible }) => {
  const [creating, setCreating] = useState(false);
  const [defaultChoice, setDefaultChoice] = useState(null);
  const [editingOptionId, setEditingOptionId] = useState(null);

  const { createOption, updateOption } = useOptionStore((state) => state);

  const formik = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      choices: [
        { id: 1, name: "", name_ar: "" },
        { id: 2, name: "", name_ar: "" },
      ],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Option name is required"),
      name_ar: Yup.string().optional(),
      choices: Yup.array()
        .of(
          Yup.object({
            name: Yup.string().required("Choice name is required"),
            name_ar: Yup.string().optional(),
          })
        )
        .min(2, "At least two choices are required"),
    }),
    onSubmit: async (values) => {
      setCreating(true);

      if (!defaultChoice) {
        toast.error("Select a default choice.");
        setCreating(false);
        return;
      }

      try {
        const formattedOption = {
          name: values.name,
          name_ar: values.name_ar,
          choices: values.choices.map((choice) => ({
            id: choice.id || null,
            name: choice.name,
            name_ar: choice.name_ar,
          })),
          default_choice: defaultChoice,
        };

        if (editingOptionId) {
          await updateOption(itemId, formattedOption, editingOptionId);
        } else {
          await createOption(itemId, formattedOption);
        }

        setEditingOptionId(null);
        formik.resetForm();
        setDefaultChoice(null);
      } catch (error) {
        console.error("Failed to create or update option", error);
      } finally {
        setCreating(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.choices.length > 0) {
      setDefaultChoice(formik.values.choices[0]);
    }
  }, [formik.values.choices]);

  const handleAddChoice = () => {
    const newChoice = { id: Date.now(), name: "", name_ar: "" };
    formik.setFieldValue("choices", [...formik.values.choices, newChoice]);
  };

  const handleRemoveChoice = (index) => {
    if (formik.values.choices.length <= 2) return;
    const updatedChoices = formik.values.choices.filter((_, i) => i !== index);

    formik.setFieldValue("choices", updatedChoices);

    if (defaultChoice?.id === formik.values.choices[index].id) {
      setDefaultChoice(updatedChoices.length > 0 ? updatedChoices[0] : null);
    }
  };

  const isAddDisabled = formik.values.choices.some(
    (choice) => choice.name.trim() === ""
  );

  return (
    <Card className="shadow-lg">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">
            {editingOptionId ? t("Edit Option") : t("Add New Option")}
          </h5>
          <Button
            color="secondary"
            outline
            onClick={() => setIsFormVisible(false)}
          >
            {t("Add Existing")}
          </Button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex gap-3 mb-3">
            <div style={{ flex: 1 }}>
              <Label htmlFor="name">{t("Title")}</Label>
              <Input
                type="text"
                id="name"
                name="name"
                {...formik.getFieldProps("name")}
                placeholder={t("Ex. Sugar")}
                invalid={formik.touched.name && Boolean(formik.errors.name)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Label htmlFor="name_ar">{t("Title (AR)")}</Label>
              <Input
                type="text"
                id="name_ar"
                name="name_ar"
                {...formik.getFieldProps("name_ar")}
                placeholder={t("Ex. السكر")}
                invalid={
                  formik.touched.name_ar && Boolean(formik.errors.name_ar)
                }
              />
            </div>
          </div>
          {/* Choices Section */}
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Label className="mb-0">{t("Choices")}</Label>
            <Button
              color="primary"
              size="sm"
              outline
              onClick={handleAddChoice}
              disabled={isAddDisabled}
            >
              {t("Add Choice")}
            </Button>
          </div>

          <div className="border p-3 rounded mb-3 bg-light">
            {formik.values.choices.map((choice, index) => (
              <div
                key={choice.id}
                className="d-flex  w-100 align-items-center gap-2 mb-2"
              >
                <Input
                  type="text"
                  value={choice.name}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `choices.${index}.name`,
                      e.target.value
                    )
                  }
                  placeholder={t("Ex. Yes")}
                  style={{ width: "40%" }}
                />

                <Input
                  type="text"
                  value={choice.name_ar}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `choices.${index}.name_ar`,
                      e.target.value
                    )
                  }
                  placeholder={t("Ex. نعم")}
                  style={{ width: "40%" }}
                />

                <Button
                  color={defaultChoice?.id === choice.id ? "success" : "info"}
                  size="sm"
                  outline
                  className="text-nowrap px-3"
                  style={{ width: "20%" }}
                  onClick={() => setDefaultChoice(choice)}
                >
                  {defaultChoice?.id === choice.id
                    ? t("Default")
                    : t("Set Default")}
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleRemoveChoice(index)}
                  disabled={formik.values.choices.length <= 2}
                >
                  <i className="ri-subtract-line"></i>
                </Button>
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <div className="d-flex justify-content-end">
            <Button type="submit" color="success" disabled={creating}>
              {creating ? <Spinner size="sm" color="light" /> : t("Save")}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default OptionForm;
