import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { RenderFormikInput } from "./../../../Components/Common/Forms/FormikInputHelper";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useMenuItemsStore } from "../../../stores/Assets/menuItems";
import { FormControlLabel, Switch } from "@mui/material";

const AddItem = ({ menuId, showAddItemModal, toggleAddItemModal }) => {
  const [showCatalog, setShowCatalog] = useState(true);
  const [catalogImg, setCatalogImg] = useState("");
  const [images, setImages] = useState([]);
  const { createItem, isLoading, catalog, getCatalog } = useMenuItemsStore(
    (state) => state
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      title_ar: "",
      description: "",
      price: "",
      available: false,
      menu_id: menuId,
      item_images: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Required")),
      title_ar: Yup.string().optional(),
      description: Yup.string().optional(),
      price: Yup.number().optional(),
      available: Yup.boolean().required(t("Required")),
      menu_id: Yup.number().required(t("Required")),
      item_images: Yup.array()
        .of(Yup.mixed().required(t("Required")))
        .required(t("Required")),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("title_ar", values.title_ar);
      formData.append("description", values.description || "");
      formData.append("price", values.price || 0);
      formData.append("available", values.available);
      formData.append("menu_id", menuId);

      values.item_images.forEach((image, index) => {
        formData.append("item_images", image);
      });

      if (catalogImg !== "") {
        formData.append("catalog_img", catalogImg);
      }

      console.log("Form Data: ", formData);
      const result = await createItem(menuId, formData);
      if (result.success) {
        formik.resetForm();
        toggleAddItemModal();
      }
    },
  });
  const fieldsToRender = [
    { fieldName: "title", label: "Title", fullWidth: true },
    { fieldName: "title_ar", label: "Title (AR)", fullWidth: true },
    {
      fieldName: "description",
      label: "Description",
      fullWidth: true,
    },
    { fieldName: "price", label: "Price", fullWidth: true },
  ];
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    formik.setFieldValue("item_images", files);
    setImages(files);
  };

  useEffect(() => {
    const fetchCatalog = async () => {
      await getCatalog();
    };
    fetchCatalog();
  }, [getCatalog]);
  return (
    <Modal isOpen={showAddItemModal} toggle={toggleAddItemModal}>
      <ModalHeader toggle={toggleAddItemModal}>
        {showCatalog ? t("Add from Catalog") : t("Add custom Item")}
      </ModalHeader>
      <ModalBody>
        {showCatalog ? (
          <Container className="mt-3">
            <Row className="g-3">
              <Col xs={12} sm={6} md={4}>
                <Card
                  className="btn shadow-sm d-flex flex-column h-100 btn btn-outline-light"
                  onClick={() => setShowCatalog(false)}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <h5 className="text-center mb-0">Add New Item</h5>
                  </CardBody>
                </Card>
              </Col>

              {catalog.map((item) => (
                <Col key={item.id} xs={12} sm={6} md={4}>
                  <Card
                    className="shadow-sm d-flex flex-column h-100"
                    onClick={() => {
                      setShowCatalog(false);
                      formik.setFieldValue("title", item.title);
                      formik.setFieldValue("title_ar", item.title_ar);
                      formik.setFieldValue("description", item.description);
                      setCatalogImg(item.image_url);
                    }}
                  >
                    <CardImg
                      top
                      src={item.image_url}
                      alt={item.title}
                      className="flex-shrink-0"
                      style={{ objectFit: "cover", height: "70%" }}
                    />
                    <CardBody className="d-flex flex-column flex-grow-1">
                      <CardTitle tag="h6" className="text-center mt-auto">
                        {item.title} - {item.title_ar}
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        ) : (
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column">
            <Row className="g-2">
              {fieldsToRender.map((field) => (
                <Col key={field.fieldName} xs={12} md={6}>
                  {RenderFormikInput(formik, field)}
                </Col>
              ))}
            </Row>

            {/* <FormControlLabel
              control={
                <Switch
                  checked={formik.values.available}
                  onChange={(event) =>
                    formik.setFieldValue("available", event.target.checked)
                  }
                />
              }
              label="Available"
              className="mt-2"
            /> */}

            {catalogImg === "" ? (
              <>
                <Label htmlFor="item_images" className="w-100 form-label mt-2">
                  {t("Item Images")}
                </Label>
                <input
                  type="file"
                  id="item_images"
                  name="item_images"
                  accept="image/*"
                  multiple
                  onChange={handleFileUpload}
                  className="bg-gradient rounded-3 form-control"
                />
                {images.length > 0 && (
                  <div className="mt-2">
                    <h6>Selected Images:</h6>
                    <ul>
                      {images.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-3 d-flex flex-column align-items-center">
                <img
                  src={catalogImg}
                  alt="catalog"
                  style={{
                    objectFit: "cover",
                    height: "120px",
                    width: "100%",
                    maxWidth: "200px",
                  }}
                />
              </div>
            )}

            <div className="d-flex justify-content-between gap-2 mt-3">
              <Button
                onClick={() => {
                  setShowCatalog(true);
                  formik.resetForm();
                  setCatalogImg("");
                }}
              >
                Use Catalog
              </Button>
              <div className="d-flex gap-2">
                <Button type="submit" color="success" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" /> : <span>{t("Add")}</span>}
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    toggleAddItemModal();
                    formik.resetForm();
                    setImages([]);
                  }}
                  disabled={isLoading}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalFooter>{/* Additional footer content can go here */}</ModalFooter>
    </Modal>
  );
};

export default AddItem;
