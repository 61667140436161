import { create } from "zustand";
import { axiosHelper, tokenHelper } from "../helpers";
import { filterNonFalseValues } from "../helpers/Utlis";

export const useSpaceStore = create((set, get) => ({
  spaces: [],
  subspaces: [],

  space: null,
  isLoading: false,
  isError: false,
  error: undefined,
  filters: {
    code: "",
    DestProv: "",
    dateFrom: "",
    dateTo: "",
    access: "",
    driverId: "",
    charger: false,
  },
  // Methods
  createSpace: async (siteID, body, kitchenID, menuIDs) => {
    set({ isLoading: true });

    try {
      // Get the access token
      const accessToken = await tokenHelper.getToken();

      // Post the site creation request
      const response = await axiosHelper.post(
        `/v2/space/create`,
        {
          ...body,
          site_id: siteID,
          kitchen_id: kitchenID,
          menu_ids: menuIDs,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      //create QR code
      console.log(response);
      if (response.space_id) {
        const qrResponse = await axiosHelper.post(
          "/qr/create",
          {
            kitchen_id: kitchenID,
            space_id: response.space_id,
            kitchen_id: kitchenID,
            menu_ids: menuIDs,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log(qrResponse);
      }

      // //linken the space to the kitchen
      // const response2 = await axiosHelper.post(
      //   `/kitchen/${kitchenID}/link-space/${response.space_id}`,
      //   body,
      //   {
      //     headers: { Authorization: `Bearer ${accessToken}` },
      //   }
      // );
      // const response3 = await axiosHelper.patch(
      //   `/menu/${menuID}/link-space/${response.space_id}`,
      //   body,
      //   {
      //     headers: { Authorization: `Bearer ${accessToken}` },
      //   }
      // );

      // Refresh the list of spaces
      await get().getSpaces(siteID);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      set({ isLoading: false });
    }
  },
  getSubSpaces: async (spaceID) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();
      const response = await axiosHelper.get(`/sub-space/space/${spaceID}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const [subSpaceResponse, qrResponse] = await Promise.all([
        axiosHelper.get(`/sub-space/space/${spaceID}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
        axiosHelper.get(`/qr/space/${spaceID}`),
      ]);

      // Combine results based on `space_id`
      const combinedSubSpaces = subSpaceResponse.map((subspace) => {
        // Find the first matching menu and QR for the current space

        const matchingQR = qrResponse.find(
          (qr) => qr?.sub_space_id === subspace?.sub_space_id
        );

        // Merge objects, adding `matchingMenu` and `qrKey` if available
        return {
          ...subspace,
          qrKey: matchingQR?.key || null,
        };
      });

      console.log(combinedSubSpaces);

      set({ subspaces: combinedSubSpaces, isLoading: false });
      return true;
    } catch (e) {
      set({ isLoading: false });

      console.log(e);
      return false;
    }
  },
  createSubSpaces: async (spaceID, body, kitchenID, menuIDs) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();
      const response = await axiosHelper.post(
        `/sub-space/create-many`,
        {
          ...body,
          kitchen_id: kitchenID,
          menu_ids: menuIDs,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      // if (response[0].sub_space_id) {
      //   response.map(async(sub)=>{
      //     const qrResponse = await axiosHelper.post(
      //       "/qr/create",
      //       {
      //         kitchen_id: kitchenID,
      //         sub_space_id: sub.sub_space_id,
      //         kitchen_id: kitchenID,
      //         menu_ids: menuIDs,
      //       },
      //       {
      //         headers: { Authorization: `Bearer ${accessToken}` },
      //       }
      //     );

      //     console.log(qrResponse);
      //   })

      // }

      get().getSubSpaces(spaceID);
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  deleteSubSpace: async (space_id, subSpaceID) => {
    try {
      const accessToken = await tokenHelper.getToken();
      const response = await axiosHelper.delete(
        `/sub-space/${parseInt(subSpaceID)}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      get().getSubSpaces(space_id);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  updateSubSpace: async (spaceID, subSpaceID, body) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();
      const response = await axiosHelper.patch(
        `/sub-space/${subSpaceID}`,
        {
          ...body,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      get().getSubSpaces(spaceID);
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  getSpace: async (spaceID) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();
      const response = await axiosHelper.get(`v2/space/${parseInt(spaceID)}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      set({ isLoading: false, space: response });
    } catch (err) {
      console.log(err);
    }
  },
  getSpaces: async (siteID) => {
    try {
      // Early exit if siteID is not provided
      if (!siteID) {
        set({ spaces: [], isLoading: false });
        return;
      }

      set({ isLoading: true });

      const accessToken = await tokenHelper.getToken();

      // Fetch data from all endpoints in parallel
      const [spaceResponse, qrResponse, menuResponse] = await Promise.all([
        axiosHelper.get(`/v2/site/${siteID}/spaces`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
        axiosHelper.get(`/qr/site/${siteID}`),
        axiosHelper.get("/menu/links/a"),
      ]);

      // Combine results based on `space_id`
      const combinedSpaces = spaceResponse.map((space) => {
        // Find the first matching menu and QR for the current space
        const matchingMenu = menuResponse.find(
          (menu) => menu?.space_id === space?.space_id
        );
        const matchingQR = qrResponse.find(
          (qr) => qr?.space_id === space?.space_id
        );

        // Merge objects, adding `matchingMenu` and `qrKey` if available
        return {
          ...space,
          ...(matchingMenu || {}),
          qrKey: matchingQR?.key || null,
        };
      });
      console.log(combinedSpaces);

      // Update state with combined results
      set({ spaces: combinedSpaces, isLoading: false });
    } catch (error) {
      console.error("Error fetching spaces:", error);
      set({ spaces: [], isLoading: false });
    }
  },

  updateSite: async (id, info) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();

      let response = await axiosHelper.patch(`/site/${id}`, info, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // console.log(response.data);
      get().getspaces();
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  updateSpace: async (id, info, selectedSite) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();

      let response = await axiosHelper.patch(`/v2/space/${id}`, info, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // console.log(response.data);
      get().getSpaces(selectedSite);
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  deleteSpace: async (space_id, siteID) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();

      let response = await axiosHelper.delete(`/v2/space/${space_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      get().getspaces(siteID);
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  setFilters: (filters) => {
    set({ filters: filterNonFalseValues(filters) });
  },
}));
