import { create } from "zustand";
import { axiosHelper, tokenHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const useOrderStore = create((set, get) => ({
  orders: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters: {
    code: "",
    DestProv: "",
    dateFrom: "",
    dateTo: "",
    access: "",
    driverId: "",
    charger: false,
  },
  // Methods
  createOrder: async (body) => {
    set({ isLoading: true });

    try {
      // Get the access token
      const accessToken = await tokenHelper.getToken();

      // Post the site creation request
      const response = await axiosHelper.post("/order/create", body, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // Refresh the list of orders
      await get().getOrders();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      set({ isLoading: false });
    }
  },
  getOrders: async (filters = {}, page = 1, limit = 10) => {
    try {
      const accessToken = await tokenHelper.getToken();
      set({ isLoading: true });

      // const queryParams = new URLSearchParams({
      //   ...filterNonFalseValues(filters),
      //   page: page.toString(),
      //   limit: limit.toString(),
      // }).toString();

      // let response2 = await axiosHelper.get(
      //   `/order/report/general?${queryParams}`,
      //   {
      //     headers: { Authorization: `Bearer ${accessToken}` },
      //   }
      // );
      let response = await axiosHelper.post(
        `/v2/order/report/general`,
        filters,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      set({ orders: response, isLoading: false });
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  updateOrder: async (id, info) => {
    try {
      const accessToken = await tokenHelper.getToken();

      set({ isLoading: true });
      let response = await axiosHelper.patch(`/order/${id}`, info, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // console.log(response.data);
      get().getOrders();
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  deleteOrder: async (menuId) => {
    try {
      set({ isLoading: true });
      const accessToken = await tokenHelper.getToken();

      let response = await axiosHelper.delete(`/menu/${menuId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // console.log(response.data);
      get().getOrders();
    } catch (e) {
      console.log(e);
    } finally {
      set({ isLoading: false });
    }
  },
  setFilters: (filters) => {
    set({ filters: filterNonFalseValues(filters) });
  },
}));
