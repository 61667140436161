import React, { useEffect } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/teaboy/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useAuth } from "../../stores";

const Register = () => {
  const { signup, isLoading, errorMsg, successMsg, clearMessages } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    clearMessages();

    console.log("successMsg", successMsg);
    console.log("errorMsg", errorMsg);
    if (successMsg) {
      // toast.success(successMsg);
      navigate("/auth/validate-email-sent");
    }
    // if (errorMsg) {
    //   toast.error(errorMsg);
    // }

    return () => {
      clearMessages();
    };
  }, [clearMessages, successMsg, errorMsg, navigate]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirm_password: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      name: Yup.string().required("Please Enter Your Name"),
      password: Yup.string().required("Please Enter Your Password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Confirm Password Isn't Match")
        .required("Confirm your password"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
    }),
    onSubmit: (values) => {
      signup(values);
    },
  });

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12} className="text-center mt-sm-5 mb-4 text-white-50">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logoLight} alt="" height="20" />
                </Link>
                <p className="mt-3 fs-15 fw-medium">
                  Elevating next-level hospitality.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Create New Account</h5>
                      <p className="text-muted">
                        Get your free ClickOrder account now
                      </p>
                    </div>

                    <Form
                      onSubmit={validation.handleSubmit}
                      className="needs-validation"
                    >
                      {[
                        "email",
                        "name",
                        "phone",
                        "password",
                        "confirm_password",
                      ].map((field) => (
                        <div className="mb-3" key={field}>
                          <Label htmlFor={field} className="form-label">
                            {field.replace("_", " ").toUpperCase()}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name={field}
                            type={
                              field.includes("password") ? "password" : "text"
                            }
                            placeholder={`Enter ${field.replace("_", " ")}`}
                            {...validation.getFieldProps(field)}
                            invalid={
                              validation.touched[field] &&
                              validation.errors[field]
                                ? true
                                : false
                            }
                          />
                          {validation.errors[field] && (
                            <FormFeedback>
                              {validation.errors[field]}
                            </FormFeedback>
                          )}
                        </div>
                      ))}

                      <Button
                        className="btn w-100 text-white font-bold"
                        style={{ background: "#ce9558" }}
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2" />
                        ) : null}
                        Sign Up
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account?
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline ms-4"
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      {/* <ToastContainer autoClose={2000} limit={1} /> */}
    </React.Fragment>
  );
};

export default Register;
