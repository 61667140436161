import React from "react";
import { Card, CardBody, CardTitle, Button, Badge } from "reactstrap";
import { t } from "i18next";
import { useConfirmDialogStore } from "../../../stores/Modal/ConfirmDialogStore";

const OptionCard = ({ option, onDelete }) => {
  const { showConfirm } = useConfirmDialogStore((state) => state);

  return (
    <Card
      className="shadow-lg p-1 rounded"
      style={{
        border: "none",
        backgroundColor: "#fff",
        transition: "0.3s ease-in-out",
      }}
    >
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <CardTitle tag="h5" className="fw-bold text-dark">
              {option?.name} {option?.name_ar && `- ${option?.name_ar}`}
            </CardTitle>
          </div>
          <div className="d-flex gap-2">
            <Button
              size="sm"
              color="danger"
              onClick={() =>
                showConfirm(
                  () => onDelete(option?.menu_item_option_id),
                  () => console.log("Cancelled"),
                  t("Confirmation"),
                  t("Are you sure you want to delete this option?")
                )
              }
              title={t("Delete")}
            >
              <i className="ri-delete-bin-fill"></i>
            </Button>
          </div>
        </div>

        <div className="d-flex flex-wrap gap-2">
          {option?.choices.map((choice) => {
            const isDefault =
              option?.default_choice?.menu_item_option_choice_id ===
              choice?.menu_item_option_choice_id;

            return (
              <Badge
                key={choice?.menu_item_option_choice_id}
                color={isDefault ? "success" : "secondary"}
                className={`px-3 py-2 rounded ${
                  isDefault ? "text-light fw-bold" : "text-dark"
                }`}
                style={{
                  fontSize: "0.9rem",
                  backgroundColor: isDefault ? "#28a745" : "#f8f9fa",
                  border: isDefault ? "none" : "1px solid #ddd",
                }}
              >
                {choice?.name} {choice?.name_ar && `- ${choice?.name_ar}`}
                {isDefault && <i className="ri-star-fill ms-2"></i>}
              </Badge>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

export default OptionCard;
