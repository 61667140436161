import React, { useEffect, useState } from "react";
import { t } from "i18next";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Spinner,
} from "reactstrap";

export const OpeningHours = ({ isOpen, toggle, info }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (info) {
      setLoading(false);
    }
  }, [info]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("View Opening Hours")}</ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center">
            <Spinner color="primary" />
            <p>{t("Loading Schedule...")}</p>
          </div>
        ) : (
          <>
            {/* Display current options */}
            <div className="d-flex flex-column gap-4 mb-2">
              {info.openingHours.map((hour, index) => (
                <Card key={index}>
                  <CardBody>
                    <CardTitle tag="h5">{t(hour.dayOfWeek)}</CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                      {t("Timezone")}: {hour.timezone}
                    </CardSubtitle>
                    <div className="d-flex justify-content-between">
                      <div>
                        <Label>{t("Start Time")}</Label>
                        <Input
                          type="time"
                          value={hour.openTime}
                          readOnly
                          className="form-control"
                        />
                      </div>
                      <div>
                        <Label>{t("End Time")}</Label>
                        <Input
                          type="time"
                          value={hour.closeTime}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline onClick={() => toggle()}>
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
