import React, { useEffect, useState } from "react";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { t } from "i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import AddOrder from "./Components/AddOrder";
import EditOrder from "./Components/EditOrder";
import { useLocation } from "react-router-dom";
import Flatpickr from "react-flatpickr";

import { useConfirmDialogStore } from "../../stores/Modal/ConfirmDialogStore";
import { useOrderStore } from "../../stores/Orders";
import moment from "moment";
import { useSiteStore } from "../../stores/Assets/site";
import { useKitchenStore } from "../../stores/Assets/kitchen";

const Orders = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [totalRows, setTotalRows] = useState(0);
  const [orderList, setOrderList] = useState([]);

  const site_id = queryParams.get("site_id"); // Get the "status" query param
  const date = queryParams.get("date"); // Get the "year" query param
  const [filters, setFilters] = useState({
    status: null,
    siteId: site_id ? site_id : NaN,
    kitchenId: NaN,
    from: date
      ? new Date(new Date(date).setDate(new Date(date).getDate() - 7))
      : new Date(Date.now() - 24 * 60 * 60 * 1000),
    to: new Date(),
  });

  document.title = "ClickOrder Admin";
  const { getOrders, isLoading, orders, deleteOrder } = useOrderStore(
    (state) => state
  );

  const [kitchenList, setKitchenList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const { getSites } = useSiteStore((state) => state);
  const { getKitchens, kitchens, getKitchensBySite } = useKitchenStore(
    (state) => state
  );

  useEffect(() => {
    // Apply filters if query params are present, otherwise fetch default data
    if (site_id || date) {
      getOrders(filters);
    }
  }, [site_id, date, getOrders]);

  const handleKitchenChange = (e) => {
    setFilters({ ...filters, kitchenId: parseInt(e.target.value) });
  };

  const handleSiteChange = (e) => {
    if (e.target.value) {
      getKitchensBySite(e.target.value);
    }
    setFilters({ ...filters, siteId: parseInt(e.target.value) });
  };

  const handleStatusChange = (e) => {
    setFilters({ ...filters, status: e.target.value });
  };

  const applyFilters = () => {
    getOrders(filters);
  };

  const resetFilters = () => {
    setFilters({
      status: null,
      siteId: NaN,
      kitchenId: NaN,
      from: new Date(Date.now() - 24 * 60 * 60 * 1000),
      to: new Date(),
    });

    getOrders({
      status: null,
      siteId: NaN,
      kitchenId: NaN,
      from: new Date(Date.now() - 24 * 60 * 60 * 1000),
      to: new Date(),
    });
  };

  useEffect(() => {
    const fetchSites = async () => {
      const siteList = await getSites(); // Fetch sites
      setSiteList(siteList);
    };

    fetchSites(); // Call the fetch function

    getKitchens(); // Fetch all kitchens
  }, [getSites]);

  useEffect(() => {
    if (kitchens.length > 0) {
      setKitchenList(kitchens);
    }
  }, [kitchens]);

  useEffect(() => {
    if (!orders) return;
    if (orders?.list?.length === 0) setOrderList([]);

    // Update orderList with the new structure
    setOrderList(orders.list || []);
    setTotalRows(orders.pagination?.totalItems || 0);
  }, [orders]);

  const statusColor = (status) => {
    if (status === "PENDING") {
      return "warning";
    }
    if (status === "DELIVERED") {
      return "success";
    }
    if (status === "CANCELLED") {
      return "danger";
    }
    if (status === "IN_PROGRESS") {
      return "secondary";
    }
    if (status === "READY") {
      return "info";
    }
  };

  const statusOptions = [
    { value: "", label: "All" },
    { value: "PENDING", label: "Pending" },
    { value: "DELIVERED", label: "Delivered" },
    { value: "CANCELED", label: "Canceled" },
    { value: "IN_PROGRESS", label: "In Progress" },
    { value: "READY", label: "Ready" },
  ];

  function processingTime(createdAt, updatedAt) {
    if (!createdAt || !updatedAt) {
      return "Invalid dates";
    }

    const createdTime = new Date(createdAt);
    const updatedTime = new Date(updatedAt);

    const timeDiff = updatedTime - createdTime;

    const seconds = Math.floor((timeDiff / 1000) % 60);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    let result = "";
    if (days > 0) result += `${days} D, `;
    if (hours > 0) result += `${hours} H, `;
    if (minutes > 0) result += `${minutes} m, `;
    if (seconds > 0) result += `${seconds} s`;

    return result || "N/A";
  }

  const columns = [
    {
      name: t("ID"),
      width: "80px",
      selector: (row) => row?.order_number,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <span>{row?.order_number}</span>
        </div>
      ),
    },
    {
      name: t("Space"),
      selector: (row) => row?.space?.name,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <span>
            {row?.space?.name} (id: {row?.space?.id})
          </span>
        </div>
      ),
    },
    {
      name: t("Customer"),
      selector: (row) => row?.customer_name,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <span>{row?.customer_name}</span>
        </div>
      ),
    },
    {
      name: t("Item"),
      selector: (row) => row?.title,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <span>{row?.title}</span>
        </div>
      ),
    },
    {
      name: t("Status"),
      selector: (row) => row?.status,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div
          style={{ fontSize: "14px" }}
          className={`badge   bg-soft-${statusColor(
            row?.status
          )} text-${statusColor(row?.status)}`}
        >
          <span>{row?.status}</span>
        </div>
      ),
    },
    {
      name: t("Source"),
      width: "100px",
      selector: (row) => row?.user_id,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div
          style={{ fontSize: "14px" }}
          className={`badge cursor-normal bg-soft-${
            row.user_id ? "success" : "danger"
          } text-${row.user_id ? "success" : "secondary"}`}
        >
          {row.user_id ? "Mobile" : "QR"}
        </div>
      ),
    },
    {
      name: t("Time"),
      selector: (row) => row?.created_at,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div>{moment(row?.created_at).format("YY/MM/DD hh:ss")}</div>
      ),
    },
    {
      name: t("Processing Time"),
      selector: (row) => row?.created_at,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div>{processingTime(row?.created_at, row?.updated_at)}</div>
      ),
    },
  ];

  const searchHandler = (searchText) => {
    // getOrders({
    //   search: searchText,
    // });
  };

  const { showConfirm } = useConfirmDialogStore((state) => state);

  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const toggleAddOrderModal = () => {
    setShowAddOrderModal(!showAddOrderModal);
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const toggleEditOrderModal = (row) => {
    setSelectedRow(row);
    setShowEditOrderModal(!showEditOrderModal);
  };

  const onChangePage = (page) => {
    getOrders({
      ...filters,
      page: page,
    });
  };

  const deleteOrderFun = async (id) => {
    try {
      await deleteOrder(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <DataTableBase
        tableTitle={"ORDERS"}
        data={orderList}
        columns={columns}
        loading={isLoading}
        paginationTotalRows={totalRows}
        onChangePage={onChangePage}
        onSearchIconClick={searchHandler}
        showActionColumn={false}
      >
        <div className="d-flex align-items-center justify-content-start gap-2 w-100">
          {statusOptions.length > 0 && (
            <div className="d-flex align-items-center mb-3 justify-content-start gap-2 w-25">
              <h5 className="">Status </h5>
              <select
                className="form-control"
                value={filters.status}
                onChange={handleStatusChange}
              >
                {statusOptions.map((status, indx) => (
                  <option key={indx} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {siteList.length > 0 && (
            <div className="d-flex align-items-center mb-3 justify-content-start gap-2 w-25">
              <h5 className="">Site </h5>
              <select
                className="form-control"
                value={filters.siteId}
                onChange={handleSiteChange}
              >
                <option key={"all"} value={NaN}>
                  {"All"}
                </option>
                {siteList.map((site, indx) => (
                  <option key={indx} value={site.site_id}>
                    {site.site_id} - {site.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {kitchenList.length > 0 && (
            <div className="d-flex align-items-center mb-3 justify-content-start gap-2 w-25">
              <h5 className="">Kitchen </h5>
              <select
                className="form-control"
                value={filters.kitchenId}
                onChange={handleKitchenChange}
              >
                <option key={"all"} value={NaN}>
                  {"All"}
                </option>
                {kitchenList.map((kitchen, indx) => (
                  <option key={indx} value={kitchen.kitchen_id}>
                    {kitchen.kitchen_id} - {kitchen.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="d-flex align-items-center mb-3 justify-content-start gap-2 w-50">
            <h5 className="">Date(s) </h5>

            <Flatpickr
              options={{
                mode: "range",
                enableTime: true,
                dateFormat: "Y-m-d H:i",
              }}
              className="form-control w-50 bg-white"
              value={[filters.from, filters.to]}
              onChange={(date) => {
                setFilters({
                  ...filters,
                  from: date[0],
                  to: date[1],
                });
              }}
            />
          </div>
          <div className="d-flex align-items-center mb-3 justify-content-start gap-2">
            <Button color="primary" onClick={applyFilters}>
              Filter
            </Button>
            <Button color="secondary" onClick={resetFilters}>
              Reset
            </Button>
          </div>
        </div>
      </DataTableBase>
      {showAddOrderModal && (
        <AddOrder
          toggleAddOrderModal={toggleAddOrderModal}
          showAddOrderModal={showAddOrderModal}
        />
      )}

      {selectedRow && (
        <EditOrder
          toggleEditOrderModal={toggleEditOrderModal}
          showEditOrderModal={showEditOrderModal}
          rowData={selectedRow}
        />
      )}
    </>
  );
};

export default Orders;
