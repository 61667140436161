import { create } from "zustand";
import { axiosHelper, tokenHelper } from "./helpers";

export const useDashboardStore = create((set, get) => ({
  dailyStats: null,
  weeklyServices: [],
  weeklyOrders: [],
  isLoading: false,
  setIsLoading: (loading) => set({ isLoading: loading }),
  setDailyStats: (data) => set({ dailyStats: data }),
  setWeeklyServices: (data) => set({ weeklyServices: data }),
  setWeeklyOrders: (data) => set({ weeklyOrders: data }),

  fetchStats: async (siteIds, day) => {
    try {
      set({ isLoading: true });

      const accessToken = await tokenHelper.getToken();
      const siteIdsString = siteIds.join(",");

      // Fetch all endpoints in parallel
      const [dailyResponse, weeklyServicesResponse, weeklyOrdersResponse] =
        await Promise.all([
          axiosHelper.get(
            `/order/stats/daily?date=${day}&sites=${siteIdsString}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          ),
          axiosHelper.get(
            `/order/stats/weekly/services?date=${day}&sites=${siteIdsString}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          ),
          axiosHelper.get(
            `/order/stats/weekly/orders?date=${day}&sites=${siteIdsString}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          ),
        ]);
      console.log(dailyResponse, weeklyOrdersResponse, weeklyServicesResponse);

      set({
        dailyStats: dailyResponse,
        weeklyServices: weeklyServicesResponse,
        weeklyOrders: weeklyOrdersResponse,
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching stats:", error);
      set({ isLoading: false });
    }
  },
}));
